import * as React from "react"
import { useState } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as style from "../../styles/recruit_faq.module.scss"
import "../../styles/recruit_faq.scss"

const Faq = () => {
  //Questionの数だけ設定
  const [isQue, setIsQue] = useState({
    q0: false,
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    q5: false,
    q6: false,
    q7: false,
    q8: false,
  })
  const [isAnc01, setIsAnc01] = useState(false)
  const [isAnc02, setIsAnc02] = useState(false)
  const [isAnc03, setIsAnc03] = useState(false)
  const [isSpAnc0, setIsSpAnc0] = useState(false)
  const [isSpAnc1, setIsSpAnc1] = useState(false)
  const [isSpAnc2, setIsSpAnc2] = useState(false)
  // const [ isSpAnc3, setIsSpAnc3 ] = useState(false);
  // const [ isSpAnc4, setIsSpAnc4 ] = useState(false);
  // const [ isSpAnc5, setIsSpAnc5 ] = useState(false);
  // const [ isSpAnc6, setIsSpAnc6 ] = useState(false);
  // const [ isSpAnc7, setIsSpAnc7 ] = useState(false);
  // const [ isSpAnc8, setIsSpAnc8 ] = useState(false);
  const [ansTitle, setAnsTitle] = useState(null)
  const [ansExplain, setAnsExplain] = useState(null)
  const [ansPos, setAnsPos] = useState("")

  //ANSER表示枠、行の数だけ設定（PC用）
  const target = [isAnc01, isAnc02, isAnc03]

  //Questionの数だけ設定
  const AnsData = [
    {
      Question: "理系・文系<br/>どちらが適合ですか？",
      Title: "理系文系は関係ありません。",
      Explain:
        "",
      PC: "setIsAnc01",
      POS: "pcLeft",
    },
    {
      Question: "キャリアアップフロー<br/>などはありますか？",
      Title: "それまでのキャリアを考慮して、一人一人に最適なキャリアをフォローします。企画・営業では、将来的には商品カテゴリや取引先を軸にした自らのチームを率いて欲しい。EC事業部ではサイト管理者として商品開発、仕入、販売、販促の一連のノウハウを身に着けて欲しい。",
      Explain:
        "",
      PC: "setIsAnc01",
      POS: "pcCenter",
    },
    {
      Question: "企業文化について<br/>教えてください",
      Title: "若手社員から社長を含めたベテラン社員まで、気兼ねなく自分の意見を発信し、相談できる企業文化です",
      Explain:
        "",
      PC: "setIsAnc01",
      POS: "pcRight",
    },
    // {
    //   "Question": "aaaaa<br/>bbbbbbbb",
    //   "Title": "1111111111queisekltea03",
    //   "Explain": "こういう挙動をするものをよく見かけますが実装の仕方がよくわかりませんでした。ググって検索してみるとなぜかjQueryで実装しているものが多く、bodyにイベントリスナーを付けている方法や応用が利かないものもあり個人的にあんまりしっくりきませんでした。それやったら自分で実装してみたらええやんと思い実装してみました！",
    //   "PC": "setIsAnc02",
    //   "POS": "pcLeft",
    // },
    // {
    //   "Question": "aaaaa<br/>bbbbbbbb",
    //   "Title": "abcdefg04",
    //   "Explain": "メニューの中、外のどちらをクリックしたか判定し外をクリックした場合はメニューを閉じるメソッド（最重要）",
    //   "PC": "setIsAnc02",
    //   "POS": "pcCenter",
    // },
    // {
    //   "Question": "aaaaa<br/>bbbbbbbb",
    //   "Title": "今回の実装の特長05",
    //   "Explain": "DOMを取得しています。吐かれるログは常に変わりません。一方e.targetは実際にクリックされたDOMを取得しています。よってクリックする場所を変えるたびにe.targetのログは変わります。ログは以下の画像の通りです。",
    //   "PC": "setIsAnc02",
    //   "POS": "pcRight",
    // },
    // {
    //   "Question": "aaaaa<br/>bbbbbbbb",
    //   "Title": "1111111111queisekltea06",
    //   "Explain": "こういう挙動をするものをよく見かけますが実装の仕方がよくわかりませんでした。ググって検索してみるとなぜかjQueryで実装しているものが多く、bodyにイベントリスナーを付けている方法や応用が利かないものもあり個人的にあんまりしっくりきませんでした。それやったら自分で実装してみたらええやんと思い実装してみました！",
    //   "PC": "setIsAnc03",
    //   "POS": "pcLeft",
    // },
    // {
    //   "Question": "aaaaa<br/>bbbbbbbb",
    //   "Title": "abcdefg07",
    //   "Explain": "メニューの中、外のどちらをクリックしたか判定し外をクリックした場合はメニューを閉じるメソッド（最重要）",
    //   "PC": "setIsAnc03",
    //   "POS": "pcCenter",
    // },
    // {
    //   "Question": "aaaaa<br/>bbbbbbbb",
    //   "Title": "今回の実装の特長08",
    //   "Explain": "DOMを取得しています。吐かれるログは常に変わりません。一方e.targetは実際にクリックされたDOMを取得しています。よってクリックする場所を変えるたびにe.targetのログは変わります。ログは以下の画像の通りです。",
    //   "PC": "setIsAnc03",
    //   "POS": "pcRight",
    // },
  ]

  const openAnswer = faqId => {
    let ansId = faqId
    let posPc = AnsData[faqId].PC
    let isShow = CheckShow()
    let targetName = "q" + ansId
    let targetShow = isQue[targetName]

    if (isShow > 0) {
      if (targetShow === false) {
        //全て非表示
        NotShow()
        //ターゲットにtrueをセット
        setIsQue({ ...isQue, [targetName]: true })
        //ターゲットを表示
        setTimeout(() => {
          if (posPc === "setIsAnc01") {
            setIsAnc01(true)
          }
          if (posPc === "setIsAnc02") {
            setIsAnc02(true)
          }
          if (posPc === "setIsAnc03") {
            setIsAnc03(true)
          }
          if (ansId === "0") {
            setIsSpAnc0(true)
          }
          if (ansId === "1") {
            setIsSpAnc1(true)
          }
          if (ansId === "2") {
            setIsSpAnc2(true)
          }
          // if ( ansId === "3") { setIsSpAnc3(true) }
          // if ( ansId === "4") { setIsSpAnc4(true) }
          // if ( ansId === "5") { setIsSpAnc5(true) }
          // if ( ansId === "6") { setIsSpAnc6(true) }
          // if ( ansId === "7") { setIsSpAnc7(true) }
          // if ( ansId === "8") { setIsSpAnc8(true) }
          setAnsTitle(AnsData[ansId].Title)
          setAnsExplain(AnsData[ansId].Explain)
          setAnsPos(AnsData[ansId].POS)
        }, 300)
      } else {
        NotShow()
      }
    } else {
      //ターゲットにtrueをセット
      setIsQue({ ...isQue, [targetName]: true })
      //ターゲットを表示
      if (posPc === "setIsAnc01") {
        setIsAnc01(true)
      }
      if (posPc === "setIsAnc02") {
        setIsAnc02(true)
      }
      if (posPc === "setIsAnc03") {
        setIsAnc03(true)
      }
      if (ansId === "0") {
        setIsSpAnc0(true)
      }
      if (ansId === "1") {
        setIsSpAnc1(true)
      }
      if (ansId === "2") {
        setIsSpAnc2(true)
      }
      // if ( ansId === "3") { setIsSpAnc3(true) }
      // if ( ansId === "4") { setIsSpAnc4(true) }
      // if ( ansId === "5") { setIsSpAnc5(true) }
      // if ( ansId === "6") { setIsSpAnc6(true) }
      // if ( ansId === "7") { setIsSpAnc7(true) }
      // if ( ansId === "8") { setIsSpAnc8(true) }
      setAnsTitle(AnsData[ansId].Title)
      setAnsExplain(AnsData[ansId].Explain)
      setAnsPos(AnsData[ansId].POS)
    }
  }

  const NotShow = () => {
    setIsAnc01(false)
    setIsAnc02(false)
    setIsAnc03(false)
    setIsSpAnc0(false)
    setIsSpAnc1(false)
    setIsSpAnc2(false)
    // setIsSpAnc3(false)
    // setIsSpAnc4(false)
    // setIsSpAnc5(false)
    // setIsSpAnc6(false)
    // setIsSpAnc7(false)
    // setIsSpAnc8(false)
    isQue["q0"] = false
    isQue["q1"] = false
    isQue["q2"] = false
    // isQue['q3'] = false
    // isQue['q4'] = false
    // isQue['q5'] = false
    // isQue['q6'] = false
    // isQue['q7'] = false
    // isQue['q8'] = false
  }

  const CheckShow = () => {
    let isAncFlag = 0
    for (let i = 0; i < target.length; i++) {
      if (target[i] === true) {
        isAncFlag++
      }
    }
    return isAncFlag
  }

  const OutsideClick = e => {
    let targetClassName = e.target.className
    let eception = [
      "item",
      "answers",
      "answersSP",
      "atitle",
      "aexplain",
      "qMark",
      "qTitle",
    ]

    let exclusion = eception.find(v => v === targetClassName)
    if (exclusion === undefined) {
      NotShow()
    }
  }

  return (
    <Layout>
      <Seo
        title="Q&A | Recruit | 株式会社アローズ"
        description="進化する、持続可能なビジネスへの挑戦。"
      />
      <div id="recruit">
        <div className={style.mv}>
          <div className={style.inner}>
            <h1>RECRUIT</h1>
            <h2 className={style.catch}>Q&A</h2>
          </div>
        </div>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={`contentsWrap`}
        onClick={e => OutsideClick(e)}
        onKeyDown={e => OutsideClick(e)}
      >
        <section className={`message`}>
          <h2>
            求職者が気になる
            <br className={style.hiddenPC} />
            アローズのこと
          </h2>
          <p>アローズについてわかりやすくQ＆A方式にまとめてみました</p>
          <div className={`qaWrap`}>
            {/* Q0 */}
            <button
              className={isQue.q0 ? "item selected" : "item"}
              onClick={() => openAnswer("0")}
            >
              <p className={"qMark"}>Q</p>
              <p
                className={"qTitle"}
                dangerouslySetInnerHTML={{ __html: AnsData[0].Question }}
              />
              <p className={"qClose"}>CLOSE</p>
            </button>

            {/* SP_0*/}
            <div className={isSpAnc0 ? "answersSp" : "notAnswersSp"}>
              <div className={`inner`}>
                <p className={`atitle`}>
                  <span>A</span>
                  {ansTitle}
                </p>
                <p className={`aexplain`}>{ansExplain}</p>
                <div className={`closeBT`}>
                  <button>button</button>
                  <p className={"closeTxt"}>CLOSE</p>
                </div>
              </div>
            </div>

            {/* Q1 */}
            <button
              className={isQue.q1 ? "item selected" : "item"}
              onClick={() => openAnswer("1")}
            >
              <p className={"qMark"}>Q</p>
              <p
                className={"qTitle"}
                dangerouslySetInnerHTML={{ __html: AnsData[1].Question }}
              />
              <p className={"qClose"}>CLOSE</p>
            </button>
            {/* SP_1*/}
            <div className={isSpAnc1 ? "answersSp" : "notAnswersSp"}>
              <div className={`inner`}>
                <p className={`atitle`}>
                  <span>A</span>
                  {ansTitle}
                </p>
                <p className={`aexplain`}>{ansExplain}</p>
                <div className={`closeBT`}>
                  <button>button</button>
                  <p className={"closeTxt"}>CLOSE</p>
                </div>
              </div>
            </div>

            {/* Q2 */}
            <button
              className={isQue.q2 ? "item selected" : "item"}
              onClick={() => openAnswer("2")}
            >
              <p className={"qMark"}>Q</p>
              <p
                className={"qTitle"}
                dangerouslySetInnerHTML={{ __html: AnsData[2].Question }}
              />
              <p className={"qClose"}>CLOSE</p>
            </button>
            {/* SP_2*/}
            <div className={isSpAnc2 ? "answersSp" : "notAnswersSp"}>
              <div className={`inner`}>
                <p className={`atitle`}>
                  <span>A</span>
                  {ansTitle}
                </p>
                <p className={`aexplain`}>{ansExplain}</p>
              </div>
              <div className={`closeBT`}>
                <button>button</button>
                <p className={"closeTxt"}>CLOSE</p>
              </div>
            </div>
          </div>

          {/* 列用 Ans 01 */}
          <div className={isAnc01 ? "answers " + ansPos : "notAnswers"}>
            <div className={`inner`}>
              <div className={`closeBT`}>
                <button>button</button>
                <p className={"closeTxt"}>CLOSE</p>
              </div>
              <p className={`atitle`}>
                <span>A</span>
                {ansTitle}
              </p>
              <p className={`aexplain`}>{ansExplain}</p>
            </div>
          </div>
          {/* <div className={`qaWrap`}> */}

          {/* Q3 */}
          {/* <button className={ isQue.q3 ? 'item selected' : 'item'} onClick={() => openAnswer('3')}>
            <p className={'qMark'}>Q</p>
            <p className={'qTitle'} dangerouslySetInnerHTML={{ __html: AnsData[3].Question }} />
            <p className={'qClose'}>CLOSE</p>
          </button> */}
          {/* SP_3*/}
          {/* <div className={ isSpAnc3 ? 'answersSp' : 'notAnswersSp'}>
            <div className={`inner`}>
              <p className={`atitle`}><span>A</span>{ansTitle}</p>
              <p className={`aexplain`}>{ansExplain}</p>
              <div className={`closeBT`}>
                <button>button</button>
                <p className={'closeTxt'}>CLOSE</p>
              </div>
            </div>
          </div> */}
          {/* </div> */}

          {/* Q4 */}
          {/* <button className={ isQue.q4 ? 'item selected' : 'item'} onClick={() => openAnswer('4')}>
            <p className={'qMark'}>Q</p>
            <p className={'qTitle'} dangerouslySetInnerHTML={{ __html: AnsData[4].Question }} />
            <p className={'qClose'}>CLOSE</p>
          </button> */}
          {/* SP_4*/}
          {/* <div className={ isSpAnc4 ? 'answersSp' : 'notAnswersSp'}>
            <div className={`inner`}>
              <p className={`atitle`}><span>A</span>{ansTitle}</p>
              <p className={`aexplain`}>{ansExplain}</p>
              <div className={`closeBT`}>
                <button>button</button>
                <p className={'closeTxt'}>CLOSE</p>
              </div>
            </div>
          </div> */}

          {/* Q5 */}
          {/* <button className={ isQue.q5 ? 'item selected' : 'item'} onClick={() => openAnswer('5')}>
            <p className={'qMark'}>Q</p>
            <p className={'qTitle'} dangerouslySetInnerHTML={{ __html: AnsData[5].Question }} />
            <p className={'qClose'}>CLOSE</p>
          </button> */}
          {/* SP_5*/}
          {/* <div className={ isSpAnc5 ? 'answersSp' : 'notAnswersSp'}>
            <div className={`inner`}>
              <p className={`atitle`}><span>A</span>{ansTitle}</p>
              <p className={`aexplain`}>{ansExplain}</p>
              <div className={`closeBT`}>
                <button>button</button>
                <p className={'closeTxt'}>CLOSE</p>
              </div>
            </div>
          </div>
        </div> */}

          {/* 列用 Ans 02 */}
          {/* <div className={ isAnc02 ? 'answers ' + ansPos : 'notAnswers'} id="ans02">
          <div className={`inner`}>
            <div className={`closeBT`}>
              <button>button</button>
              <p className={'closeTxt'}>CLOSE</p>
            </div>
            <p className={`atitle`}><span>A</span>{ansTitle}</p>
            <p className={`aexplain`}>{ansExplain}</p>
          </div>
        </div>
        <div className={`qaWrap`}> */}

          {/* Q6 */}
          {/* <button className={ isQue.q6 ? 'item selected' : 'item'} onClick={() => openAnswer('6')}>
            <p className={'qMark'}>Q</p>
            <p className={'qTitle'} dangerouslySetInnerHTML={{ __html: AnsData[6].Question }} />
            <p className={'qClose'}>CLOSE</p>
          </button> */}
          {/* SP_6*/}
          {/* <div className={ isSpAnc6 ? 'answersSp' : 'notAnswersSp'}>
            <div className={`inner`}>
              <p className={`atitle`}><span>A</span>{ansTitle}</p>
              <p className={`aexplain`}>{ansExplain}</p>
              <div className={`closeBT`}>
                <button>button</button>
                <p className={'closeTxt'}>CLOSE</p>
              </div>
            </div>
          </div> */}

          {/* Q7 */}
          {/* <button className={ isQue.q7 ? 'item selected' : 'item'} onClick={() => openAnswer('7')}>
            <p className={'qMark'}>Q</p>
            <p className={'qTitle'} dangerouslySetInnerHTML={{ __html: AnsData[7].Question }} />
            <p className={'qClose'}>CLOSE</p>
          </button> */}
          {/* SP_7*/}
          {/* <div className={ isSpAnc7 ? 'answersSp' : 'notAnswersSp'}>
            <div className={`inner`}>
              <p className={`atitle`}><span>A</span>{ansTitle}</p>
              <p className={`aexplain`}>{ansExplain}</p>
              <div className={`closeBT`}>
                <button>button</button>
                <p className={'closeTxt'}>CLOSE</p>
              </div>
            </div>
          </div> */}

          {/* Q8 */}
          {/* <button className={ isQue.q8 ? 'item selected' : 'item'} onClick={() => openAnswer('8')}>
            <p className={'qMark'}>Q</p>
            <p className={'qTitle'} dangerouslySetInnerHTML={{ __html: AnsData[8].Question }} />
            <p className={'qClose'}>CLOSE</p>
          </button> */}
          {/* SP_8*/}
          {/* <div className={ isSpAnc8 ? 'answersSp' : 'notAnswersSp'}>
            <div className={`inner`}>
              <p className={`atitle`}><span>A</span>{ansTitle}</p>
              <p className={`aexplain`}>{ansExplain}</p>
              <div className={`closeBT`}>
                <button>button</button>
                <p className={'closeTxt'}>CLOSE</p>
              </div>
            </div>
          </div>
        </div> */}

          {/* 列用 Ans 03 */}
          {/* <div className={ isAnc03 ? 'answers ' + ansPos : 'notAnswers'} id="ans03">
          <div className={`inner`}>
            <div className={`closeBT`}>
              <button>button</button>
              <p className={'closeTxt'}>CLOSE</p>
            </div>
            <p className={`atitle`}><span>A</span>{ansTitle}</p>
            <p className={`aexplain`}>{ansExplain}</p>
          </div>
        </div> */}

          <div className={style.entryBox}>
            <h3>
              アローズの募集要項・
              <br className={style.hiddenPC} />
              エントリーはこちら
            </h3>
            <div className={style.entryBoxInner}>
              <div className={style.rikunavi}>
                <div className={style.bnrImg}>
                  <StaticImage
                    src="../images/recruit-bnr01.png"
                    alt="リクナビ"
                    quality={90}
                    placeholder="dominantColor"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    layout="fixed"
                    width={199}
                    height={44}
                  />
                </div>
                <a href="/inquiry" className={`${style.toDetail} pl_slide`}>
                  リクナビからエントリー
                </a>
              </div>
              <div className={style.mynavi}>
                <div className={style.bnrImg}>
                  <StaticImage
                    src="../images/recruit-bnr02.png"
                    alt="マイナビ"
                    quality={90}
                    placeholder="dominantColor"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    layout="fixed"
                    width={172}
                    height={41}
                  />
                </div>
                <a href="/inquiry" className={`${style.toDetail} pl_slide`}>
                  マイナビからエントリー
                </a>
              </div>
            </div>
          </div>


          </section>
      </div>

      <div className={`footNav`}>
        <Link to="/mind" className={`mind`}>
          <p className={`mtM`}>MIND</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/service" className={`service`}>
          <p className={`mtM`}>SERVICE</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/about" className={`about`}>
          <p className={`mtM`}>ABOUT</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
      </div>
    </Layout>
  )
}

export default Faq
